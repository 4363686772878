import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { ContactInfo, Spacer, Text } from "components";
export const _frontmatter = {
  "title": "Contact us",
  "navBarColor": "dark",
  "footer": "simple",
  "headerButton": {
    "label": "Send email",
    "url": "mailto:office@departmentofweb.com"
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Text align="left" tag="h1" color="#110D29" mdxType="Text">
	Make it happen now
    </Text>
    <ContactInfo columns={[{
      type: "contact",
      title: "Get in touch",
      email: "office@departmentofweb.com",
      shiftPeople: ["Jakub Mikita"]
    }, {
      type: "text",
      title: "Visit us",
      content: "We live in Poland, so it would be tough to meet. But we’re always ready to make it happen"
    }, {
      type: "menu",
      title: "Follow us",
      links: [{
        title: "medium",
        link: "https://medium.com/department-of-web"
      }, {
        title: "instagram",
        link: "https://www.instagram.com/departmentofweb/"
      }, {
        title: "facebook",
        link: "https://www.facebook.com/departmentofweb"
      }, {
        title: "linkedin",
        link: "https://www.linkedin.com/company/department-of-web/"
      }, {
        title: "github",
        link: "https://github.com/BracketSpace"
      }]
    }]} mdxType="ContactInfo" />
    <Spacer height={180} responsive={{
      md: 140,
      sm: 100
    }} mdxType="Spacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      